import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccessCourseFormData,
  CardCourse,
  Course,
  IExamQuestions,
  LessonCourse,
  PostCourseViewResponse,
  ValidateAnswerResponse,
} from '@core/interfaces/frontend/course';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DownloadResponse, UtmsData } from 'src/app/core/interfaces/frontend';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  defaultCourseData: LessonCourse[] = [];
  examData: any;
  certificateUsername = '';
  courseViewId = 0;
  courseId = 0;
  public lessonActive = new BehaviorSubject<boolean>(false);
  lessonActiveUpdate = this.lessonActive.asObservable();
  public lastLesson = new BehaviorSubject<LessonCourse | null>(null);
  lastLessonUpdate = this.lastLesson.asObservable();
  private readonly EXPIRATION_DURATION_MS = 30 * 60 * 1000; // 10 minutes
  private readonly contentManager = `${environment.apiBaseUrl}/contentManager`;
  private readonly directoryBase = `${environment.apiBaseUrl}/api-directory`;
  private readonly urlRequest = `${environment.apiBaseUrl}/api-directory/courses`;
  private readonly contentTrainingApi = `${environment.apiBaseUrl}/api-directory`;
  private readonly host = `${environment.apiBaseUrl}`;
  private readonly academyPublicHost = `${environment.apiBaseUrl}/api-academy/public`;
  private readonly academyHost = `${environment.apiBaseUrl}/api-academy`;
  private isActiveDownloadSeccion = new BehaviorSubject<boolean>(false);
  activeDownload = this.isActiveDownloadSeccion.asObservable();

  private courseExternalData = new BehaviorSubject<any>(null);
  courseExternalData$ = this.courseExternalData.asObservable();

  private allCoursesData = new BehaviorSubject<any>(null);
  allCoursesData$ = this.allCoursesData.asObservable();

  public recomendedCourseActived: boolean = false;
  private formRecomendedSubject = new BehaviorSubject<any>(null);
  obsFormRecomended$ = this.formRecomendedSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    const savedCourseProgress = JSON.parse(localStorage.getItem('trackCourse') ?? 'null');
    this.courseViewId = savedCourseProgress ? savedCourseProgress.courseViewId : 0;
  }

  changeDownloadSection(value: boolean): void {
    this.isActiveDownloadSeccion.next(value);
  }

  changeLesson(value: boolean): void {
    this.lessonActive.next(value);
  }

  changeLastLesson(value: any): void {
    this.lastLesson.next(value);
  }

  getAllVideo(): Observable<any> {
    return this.httpClient.get(`${this.contentManager}/topic/video/all`);
  }

  /**
   *
   * Deprecated method getAllVideoOld review infografia.component.ts for remove this method
   */
  getAllInfographics(): Observable<any[]> {
    return this.httpClient.get(`${this.academyPublicHost}/topics/fen`).pipe(
      map((response: any) => {
        return response.filter((res: any) => res.type === 'infografia');
      })
    );
  }

  getAllVideosPhenomenonChildAcademy(): Observable<any> {
    return this.httpClient.get(`${this.academyPublicHost}/topics/fen`);
  }

  getAllCategoryPhenomenonChildAcademy(): Observable<any> {
    return this.httpClient.get(`${this.academyPublicHost}/advices`);
  }

  getAllCourses(): Observable<any> {
    return this.httpClient.get(`${this.directoryBase}/courses/all`);
  }

  addCommentById(comentario: {}, id: number): Observable<any> {
    return this.httpClient.post(`${this.urlRequest}/${id}/comments`, comentario);
  }

  postLessonCompleteView(id: number, lessonId: number): Observable<any> {
    return this.httpClient.post(`${this.urlRequest}/${id}/lessons/${lessonId}/view`, {});
  }

  getToolsTraining(): Observable<DownloadResponse[]> {
    return this.httpClient.get<DownloadResponse[]>(`${this.academyHost}/files`);
  }

  postTrainingDownload(fileId: number): Observable<any> {
    return this.httpClient.post(`${this.academyHost}/files/${fileId}/view`, {});
  }

  // #################################### PRIVATE ENDPOINTS ####################################
  postCourseById(courseId: number,utmsData:UtmsData): Observable<any> {
    return this.httpClient.post(`${this.academyHost}/courses/${courseId}/enroll`, utmsData);
  }

  getAllComments(id: number): Observable<any> {
    return this.httpClient.get(`${this.academyHost}/courses/1/comments`);
  }

  getLastCompletedExam(examId: number): Observable<any> {
    return this.httpClient.get(`${this.academyHost}/exams/${examId}/latest`);
  }

  startExamQuestionsCourse(examId: number): Observable<IExamQuestions> {
    return this.httpClient.post<IExamQuestions>(`${this.academyHost}/exams/${examId}/start`, {});
  }

  putLessonCompleted(id: number, lessonId: number): Observable<any> {
    return this.httpClient.put(`${this.academyHost}/courses/enrollments/${id}/lessons/${lessonId}/complete`, {});
  }

  validateAnswer(userExamId: number, questionId: number, answerId: number, openResponse:string): Observable<any> {
    const body = {
      userExamId,
      openResponse,
      questionId,
      answerId,
    };
    return this.httpClient.post(`${this.academyHost}/exams/answer`, body);
  }

  putCourseCompleted(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.academyHost}/courses/enrollments/${id}/complete`, data);
  }

  // #################################### PUBLIC ENDPOINTS ####################################
  getAllCoursesPublic(): Observable<CardCourse[]> {
    const cachedData = this.allCoursesData.getValue()?.data;
    const expiresAt = this.allCoursesData.getValue()?.expiresAt;

    if (cachedData && this.dataIsNotExpired(expiresAt)) {
      return new Observable<CardCourse[]>((observer) => observer.next(cachedData));
    }

    return this.httpClient.get<CardCourse[]>(`${this.academyPublicHost}/courses`).pipe(
      map((courses) => {
        return courses.map((course) => ({ ...course, type: 'course' }));
      }),
      tap((mappedCourses: any) => {
        this.allCoursesData.next({ data: mappedCourses, expiresAt: this.getExpirationAt() });
      })
    );
  }

  getCourseBySlugPublicV2(slug: string, courseId: number): Observable<Course> {
    const cachedData = this.courseExternalData.getValue()?.data;
    const expiresAt = this.courseExternalData.getValue()?.expiresAt;

    if (cachedData && this.dataIsNotExpired(expiresAt) && cachedData.courseId === courseId) {
      return new Observable((observer) => observer.next(cachedData));
    } else {
      return this.httpClient
        .get<Course>(`${this.academyPublicHost}/courses/slug/${slug}`)
        .pipe(
          tap((courseData) => this.courseExternalData.next({ data: courseData, expiresAt: this.getExpirationAt() }))
        );
    }
  }

  getCourseBySlugPublic(slug: string): Observable<Course> {
    return this.httpClient.get<Course>(`${this.academyPublicHost}/courses/slug/${slug}`);
  }

  postCourseViewPublic(courseId: number, body: AccessCourseFormData): Observable<PostCourseViewResponse> {
    return this.httpClient.post<PostCourseViewResponse>(`${this.academyPublicHost}/courses/${courseId}/view`, body);
  }

  getExamQuestionsCoursePublic(examId: number): Observable<IExamQuestions> {
    return this.httpClient.get<IExamQuestions>(`${this.academyPublicHost}/exams/${examId}`);
  }

  validateAnswerPublic(questionId: number, answerId: number): Observable<ValidateAnswerResponse> {
    const body = {
      questionId,
      answerId,
    };
    return this.httpClient.post<ValidateAnswerResponse>(`${this.academyPublicHost}/exams/answer`, body);
  }

  putCourseLessonViewPublic(courseId: number, viewId: number, body: {} = {}): Observable<any> {
    return this.httpClient.put(`${this.academyPublicHost}/courses/${courseId}/views/${viewId}`, body);
  }

  postExamViewPublic(body: any): Observable<any> {
    return this.httpClient.post(`${this.academyPublicHost}/exams/view`, body);
  }

  private getExpirationAt(): number {
    return new Date().getTime() + this.EXPIRATION_DURATION_MS;
  }

  private dataIsNotExpired(expiresAt: number): boolean {
    const currentTime = new Date().getTime();
    return expiresAt > currentTime;
  }

  saveRecomended(data: any) {
    this.formRecomendedSubject.next(data);
  }

  clearDataRecomended() {
    this.formRecomendedSubject.next(null);
  }

  geCourseRecomendedPublic(documentNumber: number): Observable<CardCourse> {
    return this.httpClient.get<CardCourse>(`${this.academyPublicHost}/courses/personalized/${documentNumber}`);
  }

  validateLog(ipAddress: string): Observable<any> {
    const requestBody = {
      ipAddress: ipAddress,
      documentNumber: "12345678"
    };
    return this.httpClient.post<any>(`${this.academyPublicHost}/courses/ip-log`,requestBody);
  }

  saveLog(ipAddress: string, documentNumber: string): Observable<any> {
    return this.httpClient.post(`${this.academyPublicHost}/courses/save-log`, { ipAddress: ipAddress, documentNumber: documentNumber });
  }
}
