import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class UtmTrackingService {
    private localStorageKey = 'utm_info';
    private sessionStorageKey = 'utm_session';
  
    constructor() {}
  
    captureUtm(): void {
        const urlParams = new URLSearchParams(window.location.search);
        const utmSource = urlParams.get('utm_source');
        const utmMedium = urlParams.get('utm_medium');
        const utmCampaign = urlParams.get('utm_campaign');
        const utmContent = urlParams.get('utm_content');
    
        const storedUtm = this.getStoredUtm();
        const sessionUtm = sessionStorage.getItem(this.sessionStorageKey);
    
        if (utmSource || utmMedium || utmCampaign || utmContent) {
          const utmData = { utmSource, utmMedium, utmCampaign, utmContent };
          if (!storedUtm || JSON.stringify(storedUtm) !== JSON.stringify(utmData)) {
            this.storeUtm(utmData);
          } 
          sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(utmData));
        } 
      }
    
      public storeUtm(utmData: { utmSource: string | null; utmMedium: string | null; utmCampaign: string | null; utmContent: string | null }): void {
        const dataWithTimestamp = {
          ...utmData,
          timestamp: new Date().getTime()
        };
        localStorage.setItem(this.localStorageKey, JSON.stringify(dataWithTimestamp));
      }
    
      public getStoredUtm(): { utmSource: string | null; utmMedium: string | null; utmCampaign: string | null; utmContent: string | null } | null {
        const utmData = localStorage.getItem(this.localStorageKey);
        return utmData ? JSON.parse(utmData) : null;
      }
    }    