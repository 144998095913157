<div class="academy-landing" >
  <academy-banner></academy-banner>
  <academy-products-section></academy-products-section>
  <div #certificateSection>
    <academy-certificate-section></academy-certificate-section>
  </div>
  <academy-videos-section ></academy-videos-section>
  <academy-webinar-section ></academy-webinar-section>
  <academy-events-section ></academy-events-section>

  <div class="register-sticky">
    <button (click)="goToRegister()">Regístrate gratis aquí</button>
  </div>
</div>
