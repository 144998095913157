import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { DataLayerService } from '@core/services/data-layer.service';
import { HeaderService } from '@core/services/header.service';
import { Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'academy-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  @ViewChild('certificateSection') certificateSection: ElementRef | undefined;
  isRedirectToCalendar = false;
  loading: boolean = false;
  private unsubscribe$ = new Subject<void>();
  fromPersonalizedCourses: boolean = false;

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private authService: AuthService,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {

    this.authService.isLogged$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigate(['/nuevo-intranet/dasboard']);
        }
      });

      this.headerService.isRedirectToCalendar$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.isRedirectToCalendar = status;
        this.redirectToCalendar();
      });
    this.fromPersonalizedCourses = localStorage.getItem('from-personalized-courses') === 'true';
    localStorage.removeItem('previewVideoEducation');
    localStorage.removeItem('courseId');
    localStorage.removeItem('from-personalized-courses');
  }

  ngAfterViewInit() {
    if (this.fromPersonalizedCourses) {
        this.certificateSection?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  redirectToCalendar(): void {
    if (this.isRedirectToCalendar) {
      this.scrollToCalendar();
      this.headerService.setRedirectToCalendar(false);
    }
  }

  scrollToCalendar(): void {
    setTimeout(() => {
      const calendarElement = document.getElementById('calendarEventsList');
      if (calendarElement) {
        calendarElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }

  goToRegister(): void {
    this.dataLayerService.selectedMainButton('Home Externo', 'Regístrate gratis aquí');
    this.router.navigate(['/registro']);
  }
  
  loadingClicked(event: boolean): void {
    this.loading = event;
  }
}
