import { AngularSvgIconModule } from 'angular-svg-icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { EllipsisModule } from 'ngx-ellipsis';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { CommonFooterBannerComponent } from '@shared/components/common-footer-banner/common-footer-banner.component';

import { CreditHealthComponent } from '@shared/components/cards/credit-health/credit-health.component';
import { TestEntrepreneurComponent } from '@shared/components/cards/test-entrepreneur/test-entrepreneur.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { ImagePipe } from '@shared/pipes/image.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AchievementModalComponent } from './components/achievement-modal/achievement-modal.component';
import { AmountInputFormComponent } from './components/amount-input-form/amount-input-form.component';
import { AmountInputComponent } from './components/amount-input/amount-input.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BannerDashboardComponent } from './components/banner-dashboard/banner-dashboard.component';
import { BannerFullscreenComponent } from './components/banner-fullscreen/banner-fullscreen.component';
import { BannerCoursesComponent } from './components/banner/banner-courses/banner-courses.component';
import { BannerVideosComponent } from './components/banner/banner-videos/banner-videos.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonAnimatedComponent } from './components/button-animated/button-animated.component';
import { ButtonComponent } from './components/button/button.component';
import { CardAllyComponent } from './components/card-ally/card-ally.component';
import { CardCommunityHomeComponent } from './components/card-community-home/card-community-home.component';
import { CardMitiendaFeatureComponent } from './components/card-mitienda-feature/card-mitienda-feature.component';
import { CardServiceHomeComponent } from './components/card-service-home/card-service-home/card-service-home.component';
import { CardServiceComponent } from './components/card-service/card-service.component';
import { CardTestimonialComponent } from './components/card-testimonial/card-testimonial.component';
import { CardVideoComponent } from './components/card-video/card-video.component';
import { CardsGridComponent } from './components/cards-grid/cards-grid.component';
import { DirectoryCardComponent } from './components/cards-grid/directory-card/directory-card.component';
import { VirtualStoreCardComponent } from './components/cards-grid/virtual-store-card/virtual-store-card.component';
import { CourseComponent } from './components/cards/course/course.component';
import { VideoComponent } from './components/cards/video/video.component';
import { WebinarComponent } from './components/cards/webinar/webinar.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CashPaymentTermComponent } from './components/cash-payment-term/cash-payment-term.component';
import { CheckboxPersonalDataComponent } from './components/checkbox-personal-data/checkbox-personal-data.component';
import { CheckboxV2Component } from './components/checkbox-v2/checkbox-v2.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommentsSectionComponent } from './components/comments-section/comments-section.component';
import { CommentsComponent } from './components/comments/comments.component';
import { BannerCardComponent } from './components/common-newsletter/banner-card/banner-card.component';
import { CarouselCardComponent } from './components/common-newsletter/carousel-card/carousel-card.component';
import { CommonNewsletterComponent } from './components/common-newsletter/common-newsletter.component';
import { NewsletterCardComponent } from './components/common-newsletter/newsletter-card/newsletter-card.component';
import { NewsletterGridComponent } from './components/common-newsletter/newsletter-grid/newsletter-grid.component';
import { TabsComponent } from './components/common-newsletter/tabs/tabs.component';
import { CongratulationsComponent } from './components/congratulations/congratulations.component';
import { CourseSwiperListComponent } from './components/course-swiper-list/course-swiper-list.component';
import { DatepickerV2Component } from './components/datepicker-v2/datepicker-v2.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DecoratedContainerComponent } from './components/decorated-container/decorated-container.component';
import { DownloadableResourcesComponent } from './components/downloadable-resources/downloadable-resources.component';
import { DropdownAgencyComponent } from './components/dropdown-agency/dropdown-agency.component';
import { DropdownBirthdateComponent } from './components/dropdown-birthdate/dropdown-birthdate.component';
import { DropdownModalComponent } from './components/dropdown-modal/dropdown-modal.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { EventsSectionComponent } from './components/events-section/events-section.component';
import { DocumentsComponent } from './components/files/documents/documents.component';
import { SelfieComponent } from './components/files/selfie/selfie.component';
import { FilterPillsV2Component } from './components/filter-pills-v2/filter-pills-v2.component';
import { FilterPillsComponent } from './components/filter-pills/filter-pills.component';
import { FooterImageComponent } from './components/footer-image/footer-image.component';
import { FormControlInfoModalComponent } from './components/form-control-info-modal/form-control-info-modal.component';
import { FormHeaderBlockComponent } from './components/form-header-block/form-header-block.component';
import { FormStepsV2Component } from './components/form-steps-v2/form-steps-v2.component';
import { FormStepsComponent } from './components/form-steps/form-steps.component';
import { GeolocationGridComponent } from './components/geolocation-grid/geolocation-grid.component';
import { GeolocationMapsComponent } from './components/geolocation-maps/geolocation-maps.component';
import { GeolocationModalComponent } from './components/geolocation-modal/geolocation-modal.component';
import { GeolocationComponent } from './components/geolocation/geolocation.component';
import { LdJsonScriptComponent } from './components/ld-json-script/ld-json-script.component';
import { LiveWebinarBannerComponent } from './components/live-webinar-banner/live-webinar-banner.component';
import { LoanRequestCardComponent } from './components/loan-request-card/loan-request-card.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { MibancoLoaderComponent } from './components/mibanco-loader/mibanco-loader.component';
import { ModalBlanketerrorComponent } from './components/modal-blanketerror/modal-blanketerror.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { ModalDniFinancingComponent } from './components/modal-dni-financing/modal-dni-financing.component';
import { ModalDniV2Component } from './components/modal-dni-v2/modal-dni-v2.component';
import { ModalDniVideoComponent } from './components/modal-dni-video/modal-dni-video.component';
import { ModalDniComponent } from './components/modal-dni/modal-dni.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { ModalRegisterComponent } from './components/modal-register/modal-register.component';
import { ModalSeidorComponent } from './components/modal-seidor/modal-seidor.component';
import { ModalSelectedComponent } from './components/modal-selected/modal-selected.component';
import { ModalTermsConditionsPoliciesComponent } from './components/modal-terms-conditions-policies/modal-terms-conditions-policies.component';
import { OptionsTabsComponent } from './components/options-tabs/options-tabs.component';
import { OtpComponent } from './components/otp/otp.component';
import { PaginationV2Component } from './components/pagination-v2/pagination-v2.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PasswordEyeComponent } from './components/password-eye/password-eye.component';
import { ProgressSpiralActionCardComponent } from './components/progress-spiral-action-card/progress-spiral-action-card.component';
import { ProgressSpiralCardComponent } from './components/progress-spiral-card/progress-spiral-card.component';
import { RecaptchaDisclaimerComponent } from './components/recaptcha-disclaimer/recaptcha-disclaimer.component';
import { ResponsiveBannerComponent } from './components/responsive-banner/responsive-banner.component';
import { RotativeBannerComponent } from './components/rotative-banner/rotative-banner.component';
import { SavingsAccountModalComponent } from './components/savings-account-modal/savings-account-modal.component';
import { SearchableInputComponent } from './components/searchable-input/searchable-input.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { TinyPaginationComponent } from './components/tiny-pagination/tiny-pagination.component';
import { TipMessageComponent } from './components/tip-message/tip-message.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { VideoSwiperListComponent } from './components/video-swiper-list/video-swiper-list.component';
import { WebinarListComponent } from './components/webinar-list/webinar-list.component';
import { YoutubeVideoIframeComponent } from './components/youtube-video-iframe/youtube-video-iframe.component';
import { ClickStopPropagation } from './directives/click-stop-propagation.directive';
import { IframeTrackerDirective } from './directives/iframe-events.directive';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { FirstWordsPipe } from './pipes/first-words.pipe';
import { currencyPipe } from './pipes/format-currency-pipe';
import { FormatTimeMMSSPipe } from './pipes/format-time-mm-ss.pipe';
import { HtmlDecodePipe } from './pipes/html-decode.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { StripTagsPipe } from './pipes/text-html-decode.pipe';
import { TransformDatePipe } from './pipes/transform-date.pipe';
import { LinkCardComponent } from './components/link-card/link-card.component';
import { FloatCustomInputComponent } from './components/float-custom-input/float-custom-input.component';

@NgModule({
  declarations: [
    BackButtonComponent,
    ButtonComponent,
    CardsGridComponent,
    CheckboxComponent,
    ClickStopPropagation,
    CommonFooterBannerComponent,
    CommonNewsletterComponent,
    CongratulationsComponent,
    DatepickerComponent,
    DecoratedContainerComponent,
    DirectoryCardComponent,
    DropdownComponent,
    FilterPillsComponent,
    FormatTimeMMSSPipe,
    GeolocationComponent,
    HtmlDecodePipe,
    IframeTrackerDirective,
    LdJsonScriptComponent,
    LoginModalComponent,
    ModalContainerComponent,
    NewsletterCardComponent,
    NewsletterGridComponent,
    OnlynumberDirective,
    OtpComponent,
    PaginationComponent,
    PaginationV2Component,
    PasswordEyeComponent,
    ResponsiveBannerComponent,
    TinyPaginationComponent,
    TipMessageComponent,
    ToggleComponent,
    TypeaheadComponent,
    VirtualStoreCardComponent,
    CheckboxV2Component,
    GeolocationGridComponent,
    LoanRequestCardComponent,
    CardTestimonialComponent,
    CardServiceComponent,
    CardMitiendaFeatureComponent,
    YoutubeVideoIframeComponent,
    OptionsTabsComponent,
    StripTagsPipe,
    BannerCoursesComponent,
    BannerVideosComponent,
    FormStepsComponent,
    SelfieComponent,
    DocumentsComponent,
    GeolocationModalComponent,
    TabsComponent,
    BannerCardComponent,
    CarouselCardComponent,
    LoadingComponent,
    DatepickerV2Component,
    currencyPipe,
    SafePipe,
    AchievementModalComponent,
    FormStepsV2Component,
    FormHeaderBlockComponent,
    DropdownModalComponent,
    GeolocationMapsComponent,
    ButtonAnimatedComponent,
    ModalDniComponent,
    ModalInfoComponent,
    ModalSelectedComponent,
    ModalDniV2Component,
    FooterImageComponent,
    ModalSeidorComponent,
    CashPaymentTermComponent,
    SafePipe,
    SavingsAccountModalComponent,
    ModalLoginComponent,
    FormControlInfoModalComponent,
    CheckboxPersonalDataComponent,
    AmountInputComponent,
    MibancoLoaderComponent,
    RecaptchaDisclaimerComponent,
    ImagePipe,
    ModalBlanketerrorComponent,
    SearchableInputComponent,
    ModalDniVideoComponent,
    ProgressSpiralCardComponent,
    ProgressSpiralActionCardComponent,
    FirstWordsPipe,
    ModalDniFinancingComponent,
    AmountInputFormComponent,
    LiveWebinarBannerComponent,
    VideoSwiperListComponent,
    CourseSwiperListComponent,
    WebinarListComponent,
    CardAllyComponent,
    CardVideoComponent,
    CommentsSectionComponent,
    TransformDatePipe,
    CommentsComponent,
    FilterPillsV2Component,
    ShortDatePipe,
    DropdownAgencyComponent,
    SkeletonComponent,
    BannerDashboardComponent,
    CardServiceHomeComponent,
    CardCommunityHomeComponent,
    BreadcrumbComponent,
    ModalRegisterComponent,
    DownloadableResourcesComponent,
    DropdownBirthdateComponent,
    RotativeBannerComponent,
    BannerFullscreenComponent,
    EventsSectionComponent,
    EventCardComponent,
    CourseComponent,
    VideoComponent,
    WebinarComponent,
    CarouselComponent,
    CreditHealthComponent,
    TestEntrepreneurComponent,
    ModalTermsConditionsPoliciesComponent,
    LinkCardComponent,
    FloatCustomInputComponent
  ],
  exports: [
    // Modules
    CommonModule,
    HttpClientModule,
    IconSpriteModule,
    NgxJsonLdModule,
    ReactiveFormsModule,
    SwiperModule,
    // Components
    BackButtonComponent,
    ButtonComponent,
    CardsGridComponent,
    CheckboxComponent,
    CommonFooterBannerComponent,
    CommonNewsletterComponent,
    CongratulationsComponent,
    DecoratedContainerComponent,
    DropdownComponent,
    DropdownBirthdateComponent,
    FilterPillsComponent,
    FilterPillsV2Component,
    GeolocationComponent,
    LdJsonScriptComponent,
    PaginationComponent,
    PaginationV2Component,
    ResponsiveBannerComponent,
    TinyPaginationComponent,
    TipMessageComponent,
    ToggleComponent,
    TypeaheadComponent,
    PasswordEyeComponent,
    DatepickerComponent,
    LoginModalComponent,
    CheckboxV2Component,
    CardTestimonialComponent,
    CardServiceComponent,
    CardMitiendaFeatureComponent,
    YoutubeVideoIframeComponent,
    OptionsTabsComponent,
    FooterImageComponent,
    AchievementModalComponent,
    FormHeaderBlockComponent,
    FormStepsV2Component,
    DropdownModalComponent,
    GeolocationMapsComponent,
    ButtonAnimatedComponent,
    SavingsAccountModalComponent,
    CheckboxPersonalDataComponent,
    RecaptchaDisclaimerComponent,
    SearchableInputComponent,
    ProgressSpiralCardComponent,
    ProgressSpiralActionCardComponent,
    MibancoLoaderComponent,
    AmountInputFormComponent,
    LiveWebinarBannerComponent,
    VideoSwiperListComponent,
    CourseSwiperListComponent,
    WebinarListComponent,
    CardAllyComponent,
    CardVideoComponent,
    CommentsSectionComponent,
    CommentsComponent,
    BreadcrumbComponent,
    RotativeBannerComponent,
    BannerFullscreenComponent,
    EventsSectionComponent,
    EventCardComponent,
    FloatCustomInputComponent,
    // Pipes
    FormatTimeMMSSPipe,
    HtmlDecodePipe,
    SafePipe,
    TransformDatePipe,
    // Directives
    ClickStopPropagation,
    IframeTrackerDirective,
    OnlynumberDirective,
    // LASTEST COMPONENTS
    GeolocationGridComponent,
    StripTagsPipe,
    BannerVideosComponent,
    BannerCoursesComponent,
    LoadingComponent,
    currencyPipe,
    ImagePipe,
    FirstWordsPipe,
    ShortDatePipe,
    SkeletonComponent,
    BannerDashboardComponent,
    CardServiceHomeComponent,
    CardCommunityHomeComponent,
    DownloadableResourcesComponent,
    CreditHealthComponent,
    TestEntrepreneurComponent,
    CourseComponent,
    VideoComponent,
    WebinarComponent,
    CarouselComponent,
    ModalTermsConditionsPoliciesComponent,
    LinkCardComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  imports: [
    CommonModule,
    EllipsisModule,
    FormsModule,
    HttpClientModule,
    IconSpriteModule.forRoot({ path: 'assets/sprites/sprite.svg' }),
    LazyLoadImageModule,
    NgbModule,
    NgxJsonLdModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    RouterModule,
    SwiperModule,
    AngularSvgIconModule,
    NgxSkeletonLoaderModule,
    SlickCarouselModule,
  ],
})
export class SharedModule {}
